import React from 'react'
import art_steps from '../assets/img/art_footsteps.png';

export const Intro = () => {
    return (
        <div className="img-bg-firts mt-4">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center text-center">	
                        <h1 className="text-hp animate__animated animate__bounceIn pt-5">
                            { `>Juro solemnemente que mis intenciones no son buenas.<` }
                        </h1>
                    </div>   
                    <img 
                        src={ art_steps } 
                        alt='Pasos...'
                        className="class-art-s animate__animated animate__fadeIn animate__delay-2s"/>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <h2 className="text-center text-hp-y">
                        Domingo
                    </h2>
                    <h1 className="text-center text-hp-big mt-4">
                        12 DIC 2021
                    </h1>
                </div>
                
            </div>
            <div className="row mt-4">
                <div className="col-12" >
                    <div className="text-hp-big text-center">
                        Hora mágica 6:00 PM
                    </div>
                </div>
            </div>
        </div>
    )
}
