import React, { useEffect, useState } from 'react';
import 'animate.css'; 

import queryString from 'query-string';

import { Header } from './components/Header';
import { fetchWithOutToken } from './helpers/fetch';
import { Content } from './components/Content';
import { NotInvited } from './components/NotInvited';

function App() {
	
	const initData = {
		visitas: '',
		n_completo: '',
		data_extra: '',
		familiar: '',
	};

	const [ dataInv, setDataInv ] = useState( initData );
	const [ seeContent, setSeeContent ] = useState( false );
	const [ loading, setLoading ] = useState( false );

	useEffect(() => {
		
		setLoading( true );
		let isApiSubscribed = true;
		
		const getInfoInvitado = async () => {
			
			const parsed = queryString.parse(window.location.search);
			try {
			
				const resp = await fetchWithOutToken("invita/get_inv", 
					{
						"mdId" : parsed.data,
						"token" : ""
					},
					"POST"
				);
				const body = await resp.json();
	
				if (body.done) {
					setDataInv( body.rows );
					setSeeContent( true );
					setLoading( false );
				}
			} catch (error) {
				console.log( error );
			}
				
				
		}
		
		if (isApiSubscribed){
			getInfoInvitado();
		}

		return () => {
			// cancel the subscription
			isApiSubscribed = false;
		};	
	}, []);
	
	

  	return (
		<>
			<Header	/>
			{
				( !loading ) 
				? <Content 
					dataInv={ dataInv }
				/>
				: <NotInvited/>
			}
					
		</>
	);
}

export default App;
