import React from 'react';
import logo from '../assets/img/logo.png';

export const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">	
                            <a 
                                className="navbar-brand" 
                                href="!#">
                                <img 
                                    src={ logo }
                                    alt="XV Dani" 
                                    className="d-inline-block align-text-top logo-mg animate__animated animate__pulse"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
