import React, { useEffect, useRef, useState } from 'react';

export const Counter = () => {

    const [timerDays, setTimerDays] = useState('00');
	const [timerHours, setTimerHours] = useState('00');
	const [timerMinutes, setTimerMinutes] = useState('00');
	const [timerSeconds, setTimerSeconds] = useState('00');

    const fnS = {
        setTimerDays,
        setTimerHours,
        setTimerMinutes,
        setTimerSeconds,
    }
    
    let interval = useRef();
    const countDownDate = new Date('Dec 12, 2021 06:00:00').getTime();
    
    const startTimer = () => {
       
        interval = setInterval( () =>{
            const now = new Date().getTime();
            const distance = countDownDate - now;
    
            const days = Math.floor( distance / ( 1000 * 60 * 60 * 24));
            const hours = Math.floor(( distance % ( 1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor(( distance % ( 1000 * 60 * 60)) / (1000 * 60 ));
            const secounds = Math.floor(( distance % ( 1000 * 60 )) / 1000);
    
    
            if( distance < 0){
                clearInterval(interval.current);
            }else{
    
                setTimerDays( days );
                setTimerHours( hours );
                setTimerMinutes( minutes );
                setTimerSeconds( secounds );
            }
    
        }, 1000)
    }

    useEffect(() => {
        startTimer( interval, countDownDate, fnS );
    });

    return (
        <div className='bg-ultra mt-4'>
            <div className="row">
                <div className="col-12"> 
                    <p className="text-center title-hp-md">
                        Faltan:
                    </p>
                </div>
                <div className="col-3 counter-mx">
                    <p className="text-center ">
                        <span> { timerDays } </span>
                    </p> 
                    <p className="text-center ">
                        Días
                    </p>
                </div>
                <div className="col-3 counter-mx">
                    <p className="text-center ">
                        <span> { timerHours } </span>
                    </p> 
                    <p className="text-center ">
                        Hrs
                    </p>
                </div>
                <div className="col-3 counter-mx">
                    <p className="text-center ">
                        <span> { timerMinutes } </span>
                    </p> 
                    <p className="text-center ">
                        Min
                    </p>
                </div>
                <div className="col-3 counter-mx">
                    <p className="text-center ">
                        <span> { timerSeconds } </span>
                    </p> 
                    <p className="text-center ">
                        Seg
                    </p>
                </div>
            </div>
        </div>
    )
}
