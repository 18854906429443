import React, { useState } from 'react'
import { Complement } from './Complement';
import { Counter } from './Counter';
import { Intro } from './Intro';
import { ModalDataExtra } from './ModalDataExtra';
import { ModalVideo } from './ModalVideo';
import { NotInvited } from './NotInvited';
import { ShowDataExtra } from './ShowDataExtra';
import { Ubication } from './Ubication';

import ReactAudioPlayer from 'react-audio-player';
import media_p from '../assets/media/hpmp3.mp3';
import img_agr from '../assets/img/agradecimientos.jpg';

export const Content = ({ dataInv }) => {

    const [ show, setShow ] = useState( false );
    const [ showDataExtra, setShowDataExtra ] = useState( false );

    const handleClose = () => setShow(false);
	const handleShow  = () => setShow(true);
    
    const handleCloseDe = () => setShowDataExtra(false);
	const handleShowDe  = () => setShowDataExtra(true);

    const showData = true;

    const { visitas, n_completo, data_extra, familiar } = dataInv;

    if( visitas === 0){
        setShow( true );
    }

    return (
        ( showData )
        ? 
        <div className="container">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="d-grid gap-2">
                        <button 
                            className="btn btn-primary" 
                            type="button"
                            onClick={ handleShow }>
                            Ver video de Invitación 
                        </button>
                    </div>
                </div>
            </div>
            <Intro  />
            <div class="d-flex justify-content-center mt-4">
                <ReactAudioPlayer
                    src={ media_p }
                    autoPlay={ true } 
                    controls
                    className="text-center"
                />
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <img 
                        alt="Dedicatoria"
                        src={ img_agr } 
                        className="img-fluid"/>
                </div>
            </div>   
            <Counter/>
            <Complement handleShow={ handleShow } />
            {
                ( data_extra !== '') && <ShowDataExtra handleShowDe={ handleShowDe }/>
            }
            <Ubication 
                name={ n_completo }
                familiar={ familiar }
            />
            <ModalVideo 
                show={ show }
                handleClose={ handleClose }
            />
            <ModalDataExtra
                show={ showDataExtra }
                handleClose={ handleCloseDe }
                words={ data_extra }
            />
        </div>
        : <NotInvited/>
    )
}
