import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-bootstrap/Modal';

import video_1 from '../assets/img/vid_Dan.mp4';

export const ModalVideo = ( { show, handleClose} ) => {
    return (
        <Modal 
            show={ show } 
            onHide={ handleClose }
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            fullscreen={true}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Estás invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReactPlayer
                    url={ video_1 }
                    className='react-player'
                    playing
                    controls
                    width='100%'
                    height='600px'
                />
            </Modal.Body>
            <Modal.Footer>
                <button 
                    type="button" 
                    className="btn btn-secondary" 
                    data-bs-dismiss="modal"
                    onClick={ handleClose }>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
        
    )
}
