import React from 'react';
import Modal from 'react-bootstrap/Modal';

export const ModalDataExtra = ( { show, handleClose, words } ) => {
    return (
        <Modal 
            show={ show } 
            onHide={ handleClose }
            keyboard={false}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Dani te dice: </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="title-saying"> 
                    { words }
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button 
                    type="button" 
                    className="btn btn-secondary" 
                    data-bs-dismiss="modal"
                    onClick={ handleClose }>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
        
    )
}
