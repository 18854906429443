import React from 'react';
import slytherin from '../assets/img/Slytherin.png';

export const ShowDataExtra = ({ handleShowDe }) => {
    return (
        <div 
            className="info-extra"
            onClick={ handleShowDe }>
            <img 
                src={ slytherin }
                className="img-fluid"
                alt="Dani te quiere decir algo"/>
            <p className="text-center title-danwtss">
                Dani quiere decirte algo
            </p>
        </div>
    )
}
