import React, { useState } from 'react'

export const Ubication = ({ name, familiar}) => {

    const showDescInv = ( parseInt(familiar) === 1) ? 'Familia ' : 'Sr(@)';

    const [animated, setAnimated] = useState("col-12");

    const handleScrollAnimate = ( ) => {
        setAnimated("col-12 anoterclassanimated");
    }

    return (
        <div 
            className="row mt-4"
            onScroll={ handleScrollAnimate }>
            <div className={ animated }>
                <div className='bg-ultra title-nhp mb-5 p-5 text-center'>
                    <p className="text-center text-family">
                        Estimad@ { showDescInv } | { name }
                    </p>
                    <h2>Te esperamos en: Calle Allende 4, Capula, 54600 Tepotzotlán, Méx</h2>
                    <h3>A las 18:00hrs</h3>
                    <h1>"FINCA DON GASPAR"</h1>
                </div>
                <iframe 
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d663.9846058659314!2d-99.23028334252633!3d19.709893268753543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21f80c1a20e35%3A0xdedfaaeb0f792795!2sFINCA%20DON%20GASPAR!5e0!3m2!1ses-419!2smx!4v1630870618734!5m2!1ses-419!2smx'
                    width='100%'
                    height='500'
                    allowFullScreen='' 
                    loading='lazy'></iframe>
            </div>
        </div>
    )
}
