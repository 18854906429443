import React from 'react';
import harryno from '../assets/img/harry_no.jpg';

export const NotInvited = () => {
    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col-12">
                    <p className='text-modal text-center'>
                        Sry... No se encontraron datos con el registro solicitado
                    </p>
                    <img 
                        src={ harryno }
                        alt='No estás invitado' 
                        className='img-fliud'/>
                    <p className='text-modal text-center'>o quizá... no estés invitado :(</p>
                </div>
            </div>
        </div>
        
    )
}
